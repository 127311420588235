.instruments.section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.instruments__title {
  font-family: Montserrat;
  font-weight: 700;
  @include font(24px, 34px);
  color: #ff7d28;
  margin-bottom: 50px;
}
.instruments .slick-list {
  overflow: hidden;
}
.instruments .slick-track {
  display: flex;
  margin-bottom: 30px;
}
.instruments .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
}
.instruments .slick-dots button {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: none;
}
.instruments .slick-dots li {
  margin-right: 10px;
}
.instruments .slick-dots li:last-child {
  margin-right: 0;
}
.instruments .slick-dots li.slick-active button {
  background: radial-gradient(44.05% 44.05% at 50% 50%, #ffe790 0%, #ffaf28 100%);
}
.instruments .slick-dots li button {
  background: #c4c4c4;
  cursor: pointer;
}
ul.slick-dots {
  padding-left: 0;
}
.instruments__block-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}
.instruments-img-wrapper {
  margin-bottom: 15px;
}
.instruments-description {
  font-family: Montserrat;
  font-weight: 700;
  @include font(24px, 29px);
  color: #ffaf28;
}
.instruments-description-drop {
  display: none;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .instruments .slick-initialized .slick-slide {
    display: flex;
    justify-content: space-between;
  }

  .instruments__block-wrapper {
    margin-bottom: 0px;
  }
  .instruments .slick-track {
    margin-bottom: 25px;
  }
  .instruments__title {
    @include font(36px, 50px);
  }
}

@media screen and (min-width: 1440px) {
  .instruments.section {
    padding-top: 50px;
    padding-bottom: 90px;
  }
  .instruments-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .instruments__title {
    @include font(48px, 67px);
    margin-bottom: 90px;
    text-align: center;
  }

  .instruments-img-wrapper {
    position: relative;
    overflow: hidden;
    margin-bottom: 0px;
  }
  .instruments-description {
    display: none;
  }
  .instruments-description-drop {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 4px dashed #ffaf28;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    padding: 77px 16px;
    @include font(36px, 44px);
    font-weight: 700;
    color: #ffaf28;
    background: rgba(56, 56, 56, 0.8);
    transform: translateY(100%);
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
    .instruments-img-wrapper:hover & {
      transform: translateY(0%);
    }
  }
  .instruments__block-wrapper {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
