.advantage {
  background: #383838;
  font-family: Montserrat;
}
.advantage-list {
  margin: 0 auto;
}
.advantage.container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.advantage-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 1.39;
  text-align: center;
  color: #ff7d28;
  margin-bottom: 30px;
}
.description-advantage-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.39;
  color: #ffaf28;
  margin-bottom: 20px;
}
.advantage-description {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.36;
  color: #ffffff;
}
.advantage-item {
  display: flex;
}
.advantage-item:not(:last-child) {
  margin-bottom: 30px;
}
.advantage .width {
  min-width: 100px;
}

li:nth-child(2n + 1) > .advantage-img-wrapper {
  margin-right: 18px;
}
li:nth-child(2n) > .advantage-img-wrapper {
  margin-left: 18px;
}

.advantage-item:nth-child(2n) {
  flex-direction: row-reverse;
}

@media screen and (min-width: 768px) {
  .advantage-title {
    font-size: 36px;
    margin-bottom: 60px;
  }
  .advantage-padding {
    padding: 0px 50px;
  }
  .description-advantage-title {
    font-size: 24px;
    line-height: 1.42;
  }
  .advantage-description {
    font-size: 18px;
    line-height: 1.33;
  }
  .width {
    min-width: 200px;
  }
  li:nth-child(2n + 1) > .advantage-img-wrapper {
    margin-right: 34px;
  }
  li:nth-child(2n) > .advantage-img-wrapper {
    margin-left: 34px;
  }
  .advantage-item:not(:last-child) {
    margin-bottom: 36px;
  }
}

@media screen and (min-width: 1440px) {
  .advantage {
    position: relative;
  }
  .advantage-item:nth-child(n) {
    flex-direction: column;
  }
  .advantage-list {
    display: flex;
    flex-wrap: wrap;
    // margin-left: calc(-1 * 60px);
    margin-top: calc(-1 * 100px);
  }
  .advantage-item {
    flex-basis: calc((100% - 3 * 60px) / 3);
    margin-top: 100px;
    margin-left: 60px;
    &:not(:last-child) {
      margin-bottom: 0px;
    }
  }
  .advantage-item:nth-child(3n + 1) {
    margin-left: 30px;
  }
  li:nth-child(2n + 1) > .advantage-img-wrapper {
    margin-right: 0px;
    margin: 0 auto;
  }
  li:nth-child(2n) > .advantage-img-wrapper {
    margin-left: 0px;
    align-items: center;
    margin: 0 auto;
  }
  .advantage-title {
    text-align: right;
    font-size: 48px;
    line-height: 1.39;
    margin-bottom: 0px;
  }
  .advantage-title::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0%;
    top: 45px;
    width: 36%;
    height: 5px;
    background-color: #ff7d28;
    border-radius: 2px;
    transform: translateY(40px);
  }
  .advantage-padding {
    padding: 0px 0px;
  }
  .advantage-list {
    margin: 0 auto;
  }
  .description-advantage-title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 9px;
  }
  .advantage-description {
    line-height: 1.39;
  }
  .width {
    margin-bottom: 9px;
  }
}
