.price.container {
  font-family: Montserrat;
  padding-top: 50px;
  padding-bottom: 25px;
}
.price__title {
  font-weight: 700;
  @include font(24px, 34px);
  color: #ff7d28;
  text-align: center;
  margin-bottom: 21px;
}
.price__item {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
}
.price__item-title {
  font-style: normal;
  font-weight: 800;
  @include font(14px, 20px);
  color: #585858;
  margin-bottom: 15px;
}
.price__item-service {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.price__cost {
  font-weight: 700;
  @include font(14px, 20px);
  letter-spacing: 0.05em;
  color: #ff7d28;
}
.price__link {
  font-weight: 700;
  @include font(14px, 20px);
  color: #383838;
  padding: 5px 36px;
}

@media screen and (max-width: 374px) {
  .price__link {
    padding: 5px 15px;
  }
}
@media screen and (min-width: 768px) {
  .price.container {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  .price__title {
    @include font(36px, 50px);
    text-align: left;
    margin-bottom: 66px;
  }
  .price__item {
    margin-bottom: 22px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .price__item-title {
    @include font(18px, 25px);
    margin-bottom: 15px;
  }

  .price__cost {
    font-weight: 700;
    @include font(18px, 25px);
  }
  .price__link {
    padding: 10px 27px;
  }
}
@media screen and (min-width: 1440px) {
  .price.container {
    position: relative;
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .price__title {
    display: block;
    position: relative;
    @include font(48px, 67px);
    margin-bottom: 102px;
    &::after {
      content: '';
      position: absolute;
      display: block;
      right: 0%;
      top: 134px;
      width: 1190px;
      height: 5px;
      background-color: #ff7d28;
      border-radius: 2px;
    }
  }
  .price__item-title {
    margin-bottom: 0;
  }
  .price__item {
    display: flex;
    align-items: center;
    margin-bottom: 38px;
  }
  .price__item-title {
    @include font(24px, 34px);
  }
  .price__item-service {
    margin-left: auto;
  }
  .price__cost {
    @include font(24px, 34px);
    letter-spacing: 0.04em;

    margin-right: 23px;
  }
  .price__link {
    @include font(18px, 25px);
    padding: 12px 19px;
  }
}
