h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  letter-spacing: 0.03em;

  text-rendering: optimizeSpeed;
}

// body {
//   color: var(--main-text-color);
//   letter-spacing: 0.03em;
//   text-rendering: optimizeSpeed;
//   height: 100%;
//   width: 100%;
// }
// .body-wrapper {
//   min-height: 100%;
//   overflow: hidden;
// }
main {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.list {
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.link {
  text-decoration: none;
  color: inherit;
  font-style: normal;
}

.section {
  padding-top: 24px;
  padding-bottom: 24px;
}
