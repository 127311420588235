.error {
  margin-top: -15px;
  display: none;
  font-size: 14px;
  line-height: 1;
  color: #ff2828;
  margin-bottom: 15px;
  padding-left: 15px;
  @media screen and (min-width: 1440px) {
    margin-top: 0px;
  }
}

.errorTel {
  margin-top: -15px;
  display: none;
  font-size: 14px;
  line-height: 1;
  color: #ff2828;
  margin-bottom: 15px;
  padding-left: 15px;
  @media screen and (min-width: 1440px) {
    margin-top: 0px;
  }
}

.errorConsultationForm {
  margin-top: -15px;
  display: none;
  font-size: 14px;
  line-height: 1;
  color: #ff2828;
  margin-bottom: 15px;
  padding-left: 15px;
  @media screen and (min-width: 1440px) {
    margin-top: 0px;
  }
}
.errorTelConsultationForm {
  margin-top: -15px;
  display: none;
  font-size: 14px;
  line-height: 1;
  color: #ff2828;
  margin-bottom: 15px;
  padding-left: 15px;
  @media screen and (min-width: 1440px) {
    margin-top: 0px;
  }
}

.consultation__form {
  font-family: Montserrat;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  margin: 0 auto;
}
.success {
  display: none;
  text-align: center;
  font-size: 1rem;
  width: 50%;
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
  margin: 0 auto 20px;
  border: 1px solid black;
  border-radius: 30px;
  background-color: #b7f1b0;
  @media screen and (min-width: 1440px) {
    font-size: 1.5rem;
  }
}
.modal-form__input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.modal-form__input-wrapper.quantity {
  display: flex;
  flex-direction: row;
}

.modal-form__input {
  font-family: Roboto, sans-serif;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 96px;
  padding-left: 17px;
  border: 1px solid #383838;

  font-weight: 700;
  font-size: 12px;
  line-height: 1.17;
  color: rgba(56, 56, 56, 0.5);
}
.modal-form__input.pay {
  color: #383838;
}

.consultation__title {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  text-align: center;
  margin-bottom: 20px;
  color: #ff7d28;
  @media screen and (min-width: 768px) {
    font-size: 36px;
    line-height: 50px;
    margin-bottom: 30px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 48px;
    line-height: 67px;
    margin-bottom: 40px;
  }
}
.consultation__form-button {
  background: radial-gradient(14.38% 469.39% at 50% 50%, #ffe791 0%, #ffaf28 100%);
  border-radius: 174px;
  align-items: center;
  margin: 0 auto;
  padding: 10px 0;
  border: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.43;
}

@media screen and (min-width: 768px) {
  .consultation__form {
    width: 100%;
    padding-left: 150px;
    padding-right: 150px;
  }

  .modal-form__input {
    width: 100%;
    height: 50px;
    border-radius: 96px;
    padding-left: 18px;
    font-size: 14px;
    line-height: 1.14;
  }
  .modal-form__input.pay {
    width: 100%;
    height: 50px;
    border-radius: 96px;
    padding-left: 18px;
    font-size: 14px;
    line-height: 1.14;
    margin-bottom: 15px;
  }
  .consultation__form-button {
    background: radial-gradient(14.38% 469.39% at 50% 50%, #ffe791 0%, #ffaf28 100%);
    border-radius: 174px;
    width: 100%;
    font-size: 18px;
    line-height: 1.39;
  }
  .consultation__form-button.pay {
    background: radial-gradient(14.38% 469.39% at 50% 50%, #ffe791 0%, #ffaf28 100%);
    border-radius: 174px;
    width: 100%;
    font-size: 18px;
    line-height: 1.39;
    height: 50px;
  }
}
@media screen and (min-width: 1440px) {
  .consultation__form.pay {
    width: 100%;
    display: block;
  }

  .modal-form__input {
    width: 100%;
    height: 50px;
    border-radius: 96px;
    padding-left: 18px;
    font-size: 14px;
    line-height: 1.14;
  }

  .consultation.section {
    padding-top: 50px;
  }
  .consultation__form {
    width: 100%;
    padding: 0px 50px 0px;
    display: flex;
  }

  .modal-form__input {
    width: 90%;
    height: 70px;
    padding-left: 35px;
    font-size: 18px;
    line-height: 1.17;
    margin-bottom: 0;
  }
  .modal-form__input-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
  .consultation__form-button {
    background: radial-gradient(14.38% 469.39% at 50% 50%, #ffe791 0%, #ffaf28 100%);
    border-radius: 174px;

    height: 70px;
    font-size: 18px;
    line-height: 1.39;
    margin: 0 0;
  }
}
