// ---палитра---
:root {
  --white-color: #ffffff;
  --lightBG-color: #eaeeec;
  --salmon-color: #ff6666;
  --teal-color: #ffaf28;
  --tealMid-color: #0ebac7;
  --tealContrast-color: #33ffff;
  --tealShade-color: #007c85;
  --darkGrey-color: #4f585e;
}
