.footer {
  background: #383838;
}
.footer-container.container {
  padding-top: 30px;
  padding-bottom: 30px;
  background: #383838;
  color: white;
}
.footer__contacts_wrapper {
  font-family: Roboto;
  display: flex;
  justify-content: space-around;
}
.footer__contact-item {
  display: inline-flex;
  margin-right: 20px;
  align-items: center;
  margin-bottom: 9px;
  font-family: Roboto;
  font-weight: 500;
  @include font(12px, 19px);
}
.footer__work-schedule-item {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  @include font(12px, 16px);
}
.footer__contact {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 50px;
  padding-right: 50px;
}
.footer__contact.list {
  flex-wrap: wrap;
}

.footer__contact-item img {
  margin-right: 5px;
}
.footer__work-schedule-item img {
  margin-right: 5px;
}
.footer__contact-title {
  font-family: Roboto;
  font-weight: 700;
  @include font(14px, 19px);
  text-align: center;
  color: #ff7d28;
  margin-bottom: 14px;
}
.footer__schedule-title {
  font-family: Roboto;
  font-weight: 700;
  @include font(14px, 19px);
  color: #ff7d28;
  margin-bottom: 14px;
}
.footer-logo {
  margin-bottom: 20px;
}
.footer__soc-title {
  font-family: Roboto;
  font-weight: 700;
  @include font(14px, 19px);
  color: #ff7d28;
  margin-bottom: 14px;
  text-align: center;
}
.footer__soc-item {
  display: flex;
  align-items: center;
}
.footer__soc-list {
  display: flex;
  justify-content: center;
  margin-bottom: 36px;
}
// .link-viber {
//   margin-right: 30px;
// }
.footer__soc-item img {
  margin-right: 9px;
}
.copyright {
  font-family: Roboto;
  font-weight: 700;
  @include font(12px, 16px);
  text-align: center;
}
.link-viber {
  font-family: Roboto;
  @include font(12px, 19px);
}
.link-telegram {
  font-family: Roboto;
  @include font(12px, 19px);
}

@media screen and (max-width: 374px) {
  .footer__contact {
    flex-wrap: nowrap;
    margin-right: 0px;
  }
}

@media screen and (min-width: 768px) {
  .footer__contact {
    padding-left: 0;
    padding-right: 0;
  }
  .footer__contacts {
    width: 250px;
    margin-right: 25px;
  }
  .footer-container.container {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    justify-content: space-between;
  }
  .footer__all-contacts {
    display: flex;
    margin-bottom: 20px;
  }

  .footer__contact-title {
    @include font(18px, 24px);
  }
  .footer__schedule-title {
    @include font(18px, 24px);
  }
  .footer__soc-title {
    @include font(18px, 24px);
  }
  .footer__contact-link {
    @include font(14px, 22px);
  }
  .footer__work-schedule-item {
    @include font(14px, 22px);
  }
  .copyright {
    @include font(12px, 16px);
    text-align: center;
    margin: 0 auto;
  }
  .footer__soc-title {
    text-align: center;
  }
  .footer__soc-item img {
    margin-right: 15px;
  }
  .footer__soc-item:last-child img {
    margin-right: 0;
  }
  .footer__soc-list {
    display: flex;
    justify-content: left;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1440px) {
  .footer-container.container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    justify-content: space-between;
  }
  .footer__all-contacts {
    margin-bottom: 0;
  }
  .footer__contact-item {
    margin-bottom: 0;
  }
  .footer__soc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer__soc-title {
    text-align: left;
  }

  .copyright {
    @include font(12px, 16px);
    text-align: center;
    margin: 0 auto;
    margin-top: 30px;
  }

  .footer__contact-title {
    text-align-last: center;
    @include font(24px, 32px);
  }
  .footer__schedule-title {
    @include font(24px, 32px);
  }
  .footer__soc-title {
    @include font(24px, 32px);
  }
  .footer__contact-link {
    @include font(18px, 28px);
  }
  .footer__work-schedule-item {
    @include font(18px, 24px);
  }

  .footer-logo {
    margin-bottom: 0;
  }
  .footer__contacts_wrapper {
    align-items: center;
    margin-right: 175px;
  }
  .footer__work-schedule {
    margin-right: 70px;
  }
  .footer__contacts {
    width: 100%;
    margin-right: 0px;
  }
}
