.cleaning .slick-list {
  overflow: hidden;
}
.cleaning .slick-track {
  display: flex;
  align-items: self-end;
  margin-bottom: 40px;
}
.cleaning .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
}
.cleaning .slick-dots button {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: none;
}
.cleaning .slick-dots li {
  margin-right: 10px;
}
.cleaning .slick-dots li:last-child {
  margin-right: 0;
}
.cleaning .slick-dots li.slick-active button {
  background: radial-gradient(44.05% 44.05% at 50% 50%, #ffe790 0%, #ffaf28 100%);
}
.cleaning .slick-dots li button {
  background: #c4c4c4;
}
ul.slick-dots {
  padding-left: 0;
}
.before-and-after.section {
  padding-bottom: 0;
}
.cleaning-title {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.42;
  color: #ff7d28;
}
.block-wrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
}
.cleaning-description-title {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.39;
  color: #000000;
  margin-bottom: 20px;
}
.cleaning-description {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.43;
  color: #000000;
}

.block-wrapper:nth-child(2n) {
  flex-direction: row-reverse;
}
.cleaning-description-wrapper {
  width: 217px;
}
.mb {
  margin-bottom: 50px;
}

@media screen and (min-width: 768px) {
  .cleaning.section {
    padding: 36px 0;
  }
  .cleaning-title {
    font-size: 36px;
    line-height: 1.39;
    margin-bottom: 26px;
  }
  .block {
    display: flex;
    justify-content: space-around;
  }
  .block-wrapper {
    align-items: center;
    flex-direction: column;
  }
  .block-wrapper:nth-child(2n) {
    flex-direction: column;
  }
  .cleaning .width {
    min-width: 164px;
  }
  .smoke-black.width {
    width: 178px;
  }
  .smoke-fire.width {
    width: 164px;
  }
  .woods.width {
    width: 235px;
  }

  .cleaning-image-mb {
    margin-bottom: 20px;
  }
  .ignition.cleaning-image-mb {
    margin-bottom: 39px;
  }
  .ignition.width {
    width: 224px;
  }
  .cleaning-description-wrapper {
    width: 280px;
  }
  .cleaning-description-title {
    font-size: 24px;
    line-height: 1.42;
  }
  .cleaning-description {
    font-size: 18px;
    line-height: 1.39;
  }
}
@media screen and (min-width: 1440px) {
  .cleaning .container {
    position: relative;
  }
  .cleaning-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
  .block-wrapper {
    margin-right: 60px;
  }
  .cleaning-wrapper .block:nth-child(2) {
    padding-top: 137px;
  }
  .cleaning-title {
    font-size: 48px;
    line-height: 1.4;
    margin-bottom: 37px;
    text-align: end;
  }
  .cleaning-title::before {
    content: '';
    position: absolute;
    display: block;
    left: 0%;
    top: 38px;
    width: 38.5%;
    height: 5px;
    background-color: #ff7d28;
    border-radius: 2px;
  }
  .cleaning-description-title {
    text-align: center;
  }
}
