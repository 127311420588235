.before-and-after {
  background: #383838;
}
.before-and-after .container {
  background: #383838;
  padding-top: 30px;
  padding-bottom: 31px;
}

.before-and-after .block-wrapper {
  margin-bottom: 0;
}
.before-and-after .slick-list {
  overflow: hidden;
}
.before-and-after .slick-track {
  display: flex;
  margin-bottom: 30px;
}
.before-and-after .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
}
.before-and-after .slick-dots button {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: none;
}
.before-and-after .slick-dots li {
  margin-right: 10px;
}
.before-and-after .slick-dots li:last-child {
  margin-right: 0;
}
.before-and-after .slick-dots li.slick-active button {
  background: radial-gradient(44.05% 44.05% at 50% 50%, #ffe790 0%, #ffaf28 100%);
}
.before-and-after .slick-dots li button {
  background: #c4c4c4;
  cursor: pointer;
}
ul.slick-dots {
  padding-left: 0;
}
.before-and-after__title {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.42;
  text-align: center;
  margin-bottom: 34px;
  color: #ff7d28;
}
.before-and-after__description {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.39;
  color: #ffffff;
}
.before-and-after .block-wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  // padding-left: 62px;
}
.before-and-after .block {
  position: relative;
}
.before-and-after__img-arrow-wrapper {
  position: absolute;
  z-index: -1;
  top: 43px;
  left: 175px;
}
.block-wrapper.first {
  padding-left: 63px;
}
.block-wrapper.second {
  padding-left: 180px;
}
@media screen and (max-width: 367px) {
  .block-wrapper.first {
    padding-left: 23px;
  }
  .block-wrapper.second {
    padding-left: 150px;
  }
  .before-and-after__img-arrow-wrapper {
    position: absolute;
    z-index: -1;
    top: 43px;
    left: 155px;
  }
}
@media screen and (min-width: 768px) {
  .before-and-after .slick-track {
    margin-bottom: 272px;
  }
  .before-and-after__title {
    font-size: 36px;
    line-height: 1.39;
    text-align: left;
    margin-bottom: 50px;
  }
  .before-and-after__description {
    font-size: 36px;
    line-height: 1.39;
  }
  .before-and-after .block-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    // padding-left: 62px;
  }
  .before-and-after .block {
    position: relative;
  }
  .before-and-after__img-arrow-wrapper {
    position: absolute;
    z-index: -1;
    top: 275px;
    left: 90px;
  }
  .block-wrapper.first {
    flex-direction: column-reverse;
    padding-left: 1px;
  }
  .block-wrapper.second {
    position: absolute;
    top: 155px;
    right: 1px;
  }
}
@media screen and (min-width: 1440px) {
  .before-and-after .container {
    position: relative;
  }
  .before-and-after .slick-track {
    margin-bottom: 272px;
  }
  .before-and-after__title {
    font-size: 48px;
    line-height: 1.4;
    text-align: right;
    margin-bottom: 43px;
  }

  .before-and-after__title::before {
    content: '';
    position: absolute;
    display: block;
    left: 0%;
    top: 65px;
    width: 700px;
    height: 5px;
    background-color: #ff7d28;
    border-radius: 2px;
  }

  .before-and-after__description {
    font-size: 36px;
    line-height: 1.39;
  }
  .before-and-after .block-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    // padding-left: 62px;
  }
  .before-and-after__wrapper {
    display: flex;
    flex-direction: row-reverse;
  }

  .before-and-after .block {
    position: relative;
  }
  .before-and-after__img-arrow-wrapper {
    position: absolute;
    z-index: -1;
    top: 330px;
    left: 372px;
  }
  .block-wrapper.first {
    flex-direction: column-reverse;
    padding-left: 187px;
  }
  .block-wrapper.second {
    position: absolute;
    top: 217px;
    right: 235px;
    margin-right: 0;
  }
  .before-and-after .slick-dots {
    display: flex;
    margin-top: -90px;
    flex-direction: column;
    align-items: start;
  }
  .before-and-after .slick-dots button {
    width: 18px;
    height: 18px;
  }
  .before-and-after .slick-dots li {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .before-and-after .slick-dots li:last-child {
    margin-bottom: 0;
  }
}
