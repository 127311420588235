.header.section {
  position: relative;
  padding-top: 86px;
  padding-bottom: 0;
}
.header-top {
  display: flex;
  justify-content: center;
  position: fixed;
  background: #383838;
  height: 86px;
  width: 100vw;
  top: 0;
  z-index: 10000;
  &.opacity {
    opacity: 0.6;
    z-index: 10000;
    transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
}
// .header-top.opacity {
//   position: fixed;
//   opacity: 0.6;
//   top: 0;
//   left: 0;
//   z-index: 100;
// }

.header-top.container {
  height: 85px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.header-menu {
  display: none;
}
.header-price .header-link {
  font-family: Montserrat;
  font-weight: 600;
  @include font(18px, 25px);
  color: #ffffff;
}
.call {
  background-color: transparent;
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  cursor: pointer;
}
.call.hero__button.position_flex {
  position: fixed;
  bottom: 60px;
  right: 30px;
  animation: shadow 3s ease infinite;
  border-radius: 50%;
}

@keyframes shadow {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 175, 40, 0.8);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(255, 175, 40, 0);
  }
}

.header-middle {
  padding-top: 300px;
  background: no-repeat url('../images/mobile/header/chistka-dimoxoda.jpg');
  // background-attachment: fixed;
  background-size: cover;
  height: 650px;
}
.header-middle.kamin {
  padding-top: 300px;
  background: no-repeat url('../images/mobile/header/kamin.jpg');
  // background-attachment: fixed;
  background-size: cover;
  height: 650px;
}
.header-middle.trubochist {
  padding-top: 300px;
  background: no-repeat url('../images/desktop/header/trubochist.jpg');
  // background-attachment: fixed;
  background-size: cover;
  height: 650px;
}
.header-middle.vent {
  padding-top: 300px;
  background: no-repeat url('../images/desktop/header/vent.jpg');
  // background-attachment: fixed;
  background-size: cover;
  height: 650px;
}

.wrapper {
  padding-top: 435px;
}

.header-middle-title {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #ffffff;
}

.header-middle-list {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.42;
  color: #ffffff;
  margin-bottom: 35px;
}
.button {
  border: none;
  padding: 10px 25px;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.42;
  color: #383838;
  background: radial-gradient(14.38% 469.39% at 50% 50%, #ffe791 0%, #ffaf28 100%);
  border-radius: 174px;
}
.backdrop {
  position: fixed;
  z-index: 11000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  &.is-hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.modal-window {
  overflow: visible;
  position: absolute;
  z-index: 11000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  min-width: 320px;
  max-height: 609px;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  .backdrop.is-hidden & {
    position: absolute;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}
.modal-window__close-btn {
  display: block;
  justify-content: center;
  align-items: center;
  top: 8px;
  right: 8px;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border: none;
  background: #ffffff;
  cursor: pointer;
  color: #383838;
  font-size: 18px;
  z-index: 12000;
}

.header-phone_dropdown {
  position: relative;
  font-family: Roboto;
  font-weight: 500;
  @include font(36px, 42px);
  color: #383838;
  margin-bottom: 22px;
  z-index: 3000;
}
.call-button__item {
  //@include font(36px, 42px);
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}
.modal-window__description {
  font-family: Montserrat;
  font-weight: 700;
  @include font(18px, 22px);
  color: #ffaf28;
  padding-right: 130px;
}
.services__list {
  padding-left: 15px;
  padding-right: 130px;
}
.services__item {
  font-family: Montserrat;
  font-weight: 600;
  @include font(12px, 17px);
  color: #383838;
}
.img-trybochist {
  position: absolute;
  bottom: 59px;
  right: 45px;
}

//LANG BUTTON

.header__lang-button {
  position: relative;

  cursor: pointer;
}

.lang-button {
  @include flex-center;
  background-color: transparent;
  padding: 5px;
  font-family: Montserrat;
  @include font(14px, 18px);
  letter-spacing: 0.01em;
  color: #ffaf28;
  text-transform: uppercase;
}

.header__lang-button:hover > .lang-button__dropdown,
.header__lang-button:focus-within > .lang-button__dropdown {
  opacity: 1;
  visibility: visible;
}

.lang-button__dropdown {
  opacity: 0;
  visibility: hidden;

  position: absolute;
  top: 35px;
  left: 20px;
  transform: translate(-50%);
  width: 60px;
  padding: 16px 0;
  border-radius: 2px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 100;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -26px;
    left: 17px;
    border: 14px solid transparent;
    border-bottom: 18px solid #ffffff;
    border-radius: 2px;
    width: 0;
    height: 0;
    z-index: 101;
  }
}

.lang-button__item:not(:last-child) {
  margin-bottom: 10px;
}

.lang-button__link {
  font-family: Montserrat;
  @include font(16px, 20px);
  letter-spacing: 0.01em;
  color: #ffaf28;
  transition: color 250ms linear;
  text-transform: uppercase;
}

.lang-button__link:hover,
.lang-button__link:focus {
  color: #ffaf28;
}

.modal-form__input-wrapper.quantity {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.plus {
  display: flex;
  justify-content: center;
  padding: 0 0;
  border: none;
  background-color: #ffffff;
  font-size: 26px;
  border-radius: 50%;
  color: #ffffff;
  background-color: green;
  width: 30px;
  height: 30px;
}

.minus {
  display: flex;
  justify-content: center;
  padding: 0 0;
  align-items: center;
  border: none;
  background-color: #ffffff;
  font-size: 30px;
  line-height: 1;
  margin-left: auto;
  border-radius: 50%;
  color: #ffffff;
  background-color: green;
  width: 30px;
  height: 30px;
}

.modal-form__input.quantity {
  text-align: center;
  height: 30px;
  border-radius: 0;
  margin-bottom: 0;
  min-width: 30px;
  max-width: 50px;
  padding-left: 0;
  border: none;
  font-size: 20px;
  color: #383838;
}

.consultation__form.pay {
  padding-left: 0;
  padding-right: 0;
}

.text-quantity {
  font-family: Montserrat;
  font-weight: 700;
}
@media screen and (max-width: 374px) {
  .img-trybochist {
    position: absolute;
    bottom: 57px;
    right: 30px;
  }
  .modal-window__description {
    padding-right: 100px;
  }
}

@media screen and (min-width: 768px) {
  .header-top {
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
  .header-top.container {
    padding-left: 75px;
    padding-right: 75px;
  }

  .header-price {
    display: none;
  }
  .header-menu {
    display: block;
  }
  .header-link {
    margin-right: 60px;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.22;
    color: #ffffff;
  }
  .header-middle {
    padding-top: 150px;
    background: no-repeat url('../images/tablet/header/chistka-dimoxoda.jpg');
    background-attachment: fixed;
    height: 500px;
  }
  .header-middle.kamin {
    padding-top: 150px;
    background: no-repeat url('../images/tablet/header/kamin.jpg');
    background-attachment: fixed;
    height: 500px;
  }
  .header-middle.trubochist {
    padding-top: 150px;
    background: no-repeat url('../images/desktop/header/trubochist.jpg');
    background-attachment: fixed;
    height: 500px;
  }
  .header-middle.vent {
    padding-top: 150px;
    background: no-repeat url('../images/desktop/header/vent.jpg');
    background-attachment: fixed;
    height: 500px;
  }
  .header-middle-title {
    font-size: 36px;
    line-height: 1.22;
    margin-bottom: 30px;
  }
  .header-middle-list {
    font-size: 24px;
    line-height: 1.41;
    margin-bottom: 45px;
  }
  .header-link:last-child {
    margin-right: 0px;
  }
  .button {
    border: none;
    padding: 12px 74px;
    font-size: 18px;
    line-height: 1.38;
    color: #383838;
  }
  .modal-window {
    width: 564px;
  }

  .modal-window__description {
    padding-right: 250px;
    @include font(20px, 24px);
  }
  .call-button__item {
    position: relative;
    padding-left: 55px;
    display: flex;
  }
  .call-button__link {
    @include font(30px, 35px);
    &::before {
      content: '';
      position: absolute;
      display: block;
      background-image: url('../images/tablet/header/telephone.png');
      width: 35px;
      height: 35px;
      top: 0;
      left: 0;
    }
  }
}
@media screen and (min-width: 1440px) {
  .header.section {
    padding-top: 111px;
  }
  .header-top {
    height: 111px;
    justify-content: center;
  }
  .header-top.container {
    height: 110px;
  }
  .header-middle {
    padding-top: 230px;
    padding-right: 0px;
    padding-left: 0px;
    background: no-repeat url('../images/desktop/header/desktop-bg.jpg');
    background-attachment: fixed;

    background-size: cover;
    height: 800px;
  }
  .header-middle.kamin {
    padding-top: 230px;
    padding-right: 0px;
    padding-left: 0px;
    background: no-repeat url('../images/desktop/header/kamin.jpg');
    background-attachment: fixed;

    background-size: cover;
    height: 800px;
  }
  .header-middle.trubochist {
    padding-top: 230px;
    padding-right: 0px;
    padding-left: 0px;
    background: no-repeat url('../images/desktop/header/trubochist.jpg');
    background-attachment: fixed;

    background-size: cover;
    height: 800px;
  }
  .header-middle.vent {
    padding-top: 230px;
    padding-right: 0px;
    padding-left: 0px;
    background: no-repeat url('../images/desktop/header/vent.jpg');
    background-attachment: fixed;

    background-size: cover;
    height: 800px;
  }

  .header-link {
    margin-right: 140px;
    font-size: 24px;
    line-height: 1.21;
  }
  .header-middle-title {
    color: #ffffff;
    font-size: 64px;

    margin-bottom: 50px;
    padding-right: 300px;
  }
  .header-middle-list {
    margin-bottom: 70px;
    color: #ffffff;
  }
  .button {
    border: none;
    padding: 20px 57px;
    font-size: 24px;
    line-height: 1.21;
    color: #383838;
  }

  .call-button__item {
    position: relative;
    padding-left: 62px;
    display: flex;
    align-items: center;
  }
  .call-button__link {
    display: flex;
    align-items: center;
    @include font(48px, 56px);
    &::before {
      content: '';
      position: absolute;
      display: block;
      background-image: url('../images/desktop/header/telephone.png');
      width: 47px;
      height: 47px;
      top: 5px;
      left: 0;
    }
  }
  .modal-window {
    width: 642px;
    max-height: 750px;
  }
  .modal-window__description {
    @include font(24px, 29px);
  }
  .img-trybochist {
    position: absolute;
    bottom: 55px;
    right: 0px;
  }
  .services__item {
    @include font(18px, 25px);
  }
}
