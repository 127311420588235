.products {
  padding-top: 20px;
  padding-bottom: 40px;
  background-image: url('../images/fon.jpg');
}
.products__title {
  position: relative;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  @include font(18px, 25px);
  text-align: center;
  color: #ff7d28;
  margin-bottom: 40px;
  @media screen and(min-width:768px) {
    @include font(36px, 50px);
  }
  @media screen and(min-width:1440px) {
    @include font(48px, 67px);
    text-align: right;
    &::before {
      content: '';
      position: absolute;
      display: block;
      left: 0%;
      top: 35px;
      width: 400px;
      height: 5px;
      background-color: #ff7d28;
      border-radius: 2px;
    }
  }
}
.list-card__title {
  font-family: 'Montserrat';
  font-weight: 500;
  @include font(14px, 17px);
  margin-bottom: 16px;
  text-align: center;
}
.list-card__text {
  font-family: 'Montserrat';
  font-weight: 700;
  @include font(18px, 25px);
  letter-spacing: 0.05em;
  color: #ff7d28;
}

.list-card {
  // margin-top: -20px;
  & > :first-child {
    margin-top: 0;
  }
}
.list-card-ua {
  // margin-top: -20px;
  & > :first-child {
    margin-top: 0;
  }
}

.list-card__item {
  border-radius: 0 0 10px 10px;
  margin-top: 20px;
}
.list-card__link {
  display: block;
  border-radius: 0 0 10px 10px;
  padding-bottom: 10px;
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 500ms cubic-bezier(0.4, 0, 0.2, 1);
  &:hover,
  &:focus {
    transform: scale(1.05);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}
.list-card__link.pay {
  display: block;
  border-radius: 0 0 10px 10px;
  padding-bottom: 10px;
  transition: none;
  &:hover,
  &:focus {
    transform: none;
    box-shadow: none;
  }
}

.list-card__image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
}

.list-card__image-wrapper img {
  min-width: 149px;
  height: 187px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  object-fit: cover;
}

.list-card__pay {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.list-card__content {
  padding-left: 10px;
  padding-right: 10px;
}

.products .button {
  border: none;
  padding: 9px 46px;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.42;
  color: #383838;
  background: radial-gradient(14.38% 469.39% at 50% 50%, #ffe791 0%, #ffaf28 100%);
  border-radius: 174px;
}

@media screen and (min-width: 768px) {
  .products {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .list-card {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(-1 * 30px);
    margin-top: calc(-1 * 30px);
  }
  .list-card-ua {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(-1 * 30px);
    margin-top: calc(-1 * 30px);
  }
  .list-card__item {
    .list-card > & {
      flex-basis: calc(100% / 2 - 30px);
      margin-left: 30px;
      margin-top: 30px;
    }
    .list-card-ua > & {
      flex-basis: calc(100% / 2 - 30px);
      margin-left: 30px;
      margin-top: 30px;
    }
    &:not(:last-child) {
      margin-bottom: 0px;
    }
  }

  .list-card__image-wrapper img {
    min-width: 150px;
    height: 187px;
    object-fit: cover;
  }
}

@media screen and (min-width: 1440px) {
  .list-card__item {
    .list-card > & {
      flex-basis: calc(100% / 3 - 30px);
      margin-left: 30px;
      margin-top: 30px;
    }
  }
  .list-card__item {
    .list-card-ua > & {
      flex-basis: calc(100% / 3 - 30px);
      margin-left: 30px;
      margin-top: 30px;
    }
  }

  .list-card__image-wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .list-card__image-wrapper img {
    min-width: 200px;
    height: 250px;
  }

  .list-card__image-wrapper.pay img {
    min-width: 150px;
    height: 187px;
  }
  .list-card__title {
    @include font(16px, 22px);
    margin-bottom: 23px;
  }
}

// .list-card__content {
//   padding: 10px 0 10px 4px;
//   border-radius: 0 0 10px 10px;
//   @media screen and (min-width: 768px) {
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     height: 68px;
//   }
//   // background-color: #fff;
// }
