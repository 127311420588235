.contacts.section {
  @media screen and(min-width:1440px) {
    padding-top: 50px;
  }
}
.contacts__contact-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  @include font(24px, 34px);
  color: #ff7d28;
  text-align: center;
  margin-bottom: 30px;
  @media screen and(min-width: 768px) {
    @include font(44px, 62px);
    margin-bottom: 50px;
  }
  @media screen and(min-width: 1440px) {
    @include font(64px, 64px);
  }
}
.contacts__all-contacts__map-wrapper {
  @media screen and(min-width:1440px) {
    display: flex;
  }
}

.contacts__all-contacts-wrapper {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1440px) {
    min-width: 435px;
  }
}

.contacts__wrapper {
  display: flex;
}

.contacts__contact-list {
  @media screen and(min-width: 768px) {
    margin-right: 100px;
  }
  @media screen and(min-width: 1440px) {
    margin-right: 49px;
  }
}

.contacts__contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and(min-width: 768px) {
    margin-bottom: 16px;
  }
  @media screen and(min-width: 1440px) {
    width: 100%;
    margin-bottom: 24px;
  }
}

.contacts__work-schedule {
  margin-left: auto;
}

.contacts__contact-item .phone,
.contacts__contact-item .adress,
.contacts__contact-item .clock,
.contacts__contact-item .mail {
  margin-right: 10px;
  width: 25px;
  height: 25px;
  @media screen and(min-width: 768px) {
    margin-right: 20px;
    width: 25px;
    height: 25px;
  }
  @media screen and(min-width: 1440px) {
    margin-right: 12px;
    width: 28px;
    height: 28px;
  }
}

.contacts__contact-link {
  font-family: 'Roboto';
  font-weight: 500;
  @include font(14px, 14px);
  color: #383838;
  @media screen and(min-width: 768px) {
    @include font(24px, 24px);
  }
  @media screen and(min-width: 1440px) {
    display: block;
    @include font(18px, 18px);
  }
  @media screen and(max-width: 374px) {
    @include font(12px, 12px);
  }
}

.contacts__work-schedule .contacts__contact-item {
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 0;
  }

  @media screen and(min-width: 1440px) {
    margin-bottom: 40px;
  }
}

.contacts__adress-description,
.contacts__work-time-description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  @include font(14px, 14px);
  color: #383838;
  @media screen and(max-width: 374px) {
    @include font(12px, 12px);
  }
  @media screen and(min-width: 768px) {
    @include font(24px, 24px);
  }
  @media screen and(min-width: 1440px) {
    @include font(18px, 18px);
  }
}

.contacts__map {
  width: 100%;
  @media screen and(min-width: 1440px) {
    width: 715px;
    height: 541px;
    margin-left: auto;
  }
}

.contacts__map-img {
  width: 100%;
}
.contacts .consultation .container {
  @media screen and(min-width: 1440px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.contacts .consultation .consultation__title {
  @media screen and(min-width: 1440px) {
    @include font(26px, 32px);
  }
}

.contacts .consultation .consultation__form {
  @media screen and(max-width: 768px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  @media screen and(min-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media screen and(min-width: 1440px) {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.contacts .consultation.section {
  @media screen and(min-width: 768px) {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}

.contacts .consultation .modal-form__input {
  @media screen and(min-width: 1440px) {
    width: 100%;
    height: 52px;
    margin-bottom: 25px;
  }
}

.contacts .consultation .consultation__form-button {
  @media screen and(min-width: 1440px) {
    height: 52px;
    margin-bottom: 25px;
  }
}
