.form-call-back.container {
  padding-top: 30px;
  padding-bottom: 30px;
}
.form-call-back__wrapper {
  margin: 0 auto;
  padding: 40px 10px;
  box-shadow: 0px 6px 20px 13px rgba(88, 88, 88, 0.2);
  border-radius: 24px;
}
.call-back {
  padding-left: 20px;
  padding-right: 20px;
}
.form-call-back__title {
  font-family: Montserrat;
  font-weight: 700;
  @include font(20px, 21px);
  text-align: center;
  color: #585858;
  margin-bottom: 10px;
}
.form-call-back__call-back {
  font-family: Roboto, sans-serif;
  font-weight: 500;
  @include font(14px, 16px);
  text-align: center;
  color: #585858;
}
.consultation__form-button {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .form-call-back.container {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .form-call-back__wrapper {
    padding: 45px 70px;
  }
  .call-back {
    padding-left: 80px;
    padding-right: 80px;
  }
  .form-call-back__title {
    @include font(24px, 28px);
    margin-bottom: 10px;
  }
  .form-call-back__call-back {
    @include font(18px, 21px);
  }
}
@media screen and (min-width: 1440px) {
  .form-call-back.container {
    position: relative;
    padding: 85px 310px;
    margin: 0px auto;
  }
  .form-call-back.container {
    position: relative;
    padding: 85px 310px;
    margin: 0px auto;
    &::after {
      content: '';
      position: absolute;
      display: block;
      width: 408px;
      height: 575px;
      background-image: url('../images/desktop/form-call-back/trub.png');
      right: 108px;
      bottom: 0;
    }
  }
  .form-call-back__wrapper {
    padding: 51px 100px;
  }
  .call-back {
    padding-left: 110px;
    padding-right: 110px;
  }
  .form-call-back__title {
    @include font(36px, 42px);
    margin-bottom: 10px;
  }
  .form-call-back__call-back {
    @include font(24px, 28px);
  }
  .form-call-back .modal-form__input::placeholder {
    @include font(18px, 21px);
    color: rgba(88, 88, 88, 0.7);
  }
  .form-call-back .consultation__form-button {
    @include font(24px, 28px);
  }
}
