.additional-service.section {
  padding-bottom: 0px;
}
.additional-service__title {
  font-family: Montserrat;
  font-weight: 700;
  @include font(24px, 34px);
  text-align: center;
  color: #ff7d28;
  margin-bottom: 50px;
}
.additional-service__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.additional-service__list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 36px;
  &:last-child {
    margin-bottom: 0;
  }
}
.additional-service__img {
  margin-bottom: 20px;
}
.additional-service__description {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.additional-service__name {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  @include font(18px, 21px);
  color: #000000;
  margin-bottom: 20px;
}
.additional-service__link {
  padding: 10px 55px;
  font-family: Montserrat;
  font-weight: 700;
  @include font(14px, 20px);
  color: #383838;
}

@media screen and (min-width: 768px) {
  .additional-service__title {
    @include font(36px, 50px);
    margin-bottom: 50px;
    text-align: left;
  }
  .additional-service__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .additional-service__list-item {
    margin-bottom: 36px;
    &:last-child {
      margin-bottom: 36px;
    }
  }

  .additional-service__name {
    width: 181px;
    text-align: center;
  }

  .additional-service__img {
    width: 149px;
    height: 149px;
  }

  .additional-service__link {
    padding: 5px 26px;
  }
}
@media screen and (min-width: 1440px) {
  .additional-service__title {
    @include font(48px, 67px);
    margin-bottom: 110px;
    text-align: right;
  }
  .additional-service__title::before {
    content: '';
    position: absolute;
    display: block;
    left: 0%;
    top: 85px;
    width: 900px;
    height: 5px;
    background-color: #ff7d28;
    border-radius: 2px;
  }
  .additional-service__title.ua::before {
    content: '';
    position: absolute;
    display: block;
    left: 0%;
    top: 85px;
    width: 840px;
    height: 5px;
    background-color: #ff7d28;
    border-radius: 2px;
  }
  .additional-service.container {
    position: relative;
    padding-top: 50px;
    padding-bottom: 85px;
  }
  .additional-service__list {
    display: flex;
    justify-content: space-between;
  }

  .additional-service__name {
    @include font(24px, 28px);
    margin-bottom: 40px;
  }
  .additional-service__link {
    @include font(24px, 34px);
    padding: 18px 55px;
  }

  .additional-service__name {
    width: 325px;
  }
  .additional-service__img {
    margin-bottom: 40px;
    width: 331px;
    height: 331px;
  }
  .additional-service__list-item {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 50px;
    }
  }
}
