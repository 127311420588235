@media screen and (max-width: 374px) {
  .margin-bottom5 {
    margin-bottom: 37px;
  }
}
.problems {
  position: relative;
  font-family: Montserrat;
  padding-top: 50px;
  padding-bottom: 50px;
}
.problems-container {
  background: #fff;
}
.problems-list {
  display: flex;
  position: relative;
}
.slick-track {
  padding-top: 10px;
  padding-bottom: 10px;
}

.problems-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.42;
  color: #ff7d28;
  text-align: left;
  margin-bottom: 20px;
}
.problems-item {
  padding: 38px 28px;
  background: #383838;
  box-shadow: 0px 4px 27px 7px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  margin: 0 8.5px;
  border: 3px solid #ffaf28;
  height: 322px;
}
.problems-descriprtion-title {
  background: radial-gradient(14.38% 469.39% at 50% 50%, #ffe791 0%, #ffaf28 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 1.17;
  margin-bottom: 37px;
}

.margin-bottom3,
.margin-bottom4 {
  margin-bottom: 16px;
}
.margin-bottom5,
.margin-bottom2 {
  margin-bottom: 58px;
}
.problems-descriprtion {
  height: 161px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid #ffffff;
  border-top: 3px solid #ffffff;
  color: #ffffff;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 1.14;
}
.problems-description-item1 {
  margin-bottom: 20px;
}

.problems-list.container {
  padding-left: 0;
  padding-right: 0;
}
.slick-prev.slick-arrow {
  position: absolute;
  align-self: end;
  left: 5px;
  bottom: -30px;
  background: none;
  border: none;
  width: 43px;
  height: 16px;
  background-image: url(../images/mobile/problems/arrow_left.png);
  background-size: cover;
  font-size: 0;
  cursor: pointer;
}
.slick-next.slick-arrow {
  position: absolute;
  align-self: end;
  right: 5px;
  bottom: -30px;
  background: none;
  border: none;
  width: 43px;
  height: 16px;
  background-image: url(../images/mobile/problems/arrow_right.png);
  background-size: cover;
  font-size: 0;
  cursor: pointer;
}
@media screen and (max-width: 374px) {
  .margin-bottom2 {
    margin-bottom: 37px;
  }
  .margin-bottom4 {
    margin-bottom: 16px;
  }
}

@media screen and (min-width: 768px) {
  .problems-list.container,
  .container {
    padding-left: 75px;
    padding-right: 75px;
  }

  .problems-title {
    font-size: 36px;
    line-height: 1.39;
    margin-bottom: 63px;
  }
  .problems-item {
    margin: 0 21.5px;
  }
  .slick-prev.slick-arrow {
    left: 70px;
    bottom: -50px;
    width: 70px;
    height: 24px;
    background-image: url(../images/desktop/problems/arrow_left.png);
  }
  .slick-next.slick-arrow {
    right: 70px;
    bottom: -50px;
    width: 70px;
    height: 24px;
    background-image: url(../images/desktop/problems/arrow_right.png);
  }
  .slick-track {
    display: flex;
    align-items: center;
  }
  .margin-bottom2 {
    margin-bottom: 37px;
  }
}
@media screen and (min-width: 1440px) {
  .problems {
    padding-top: 50px;
    padding-bottom: 100px;
  }
  .problems-container {
    position: relative;
    margin: 0 auto;
    width: 1440px;
  }
  .problems-item {
    margin: 0 46.5px;
    padding: 38px 0px;
  }
  .problems-descriprtion-title {
    text-align: center;
    font-size: 24px;
    line-height: 1.17;
    margin-bottom: 25px;
  }
  .problems-descriprtion {
    height: 161px;
    margin-left: 28px;
    margin-right: 28px;
    font-size: 18px;
    line-height: 1.17;
  }
  .margin-bottom2,
  .margin-bottom5,
  .margin-bottom6 {
    margin-bottom: 53px;
  }
  .problems-title {
    padding-left: 98px;
    font-size: 48px;
    line-height: 1.4;
    margin-bottom: 60px;
  }
  // .problems-title::after {
  //   content: ' ';
  //   display: block;
  //   position: absolute;
  //   right: 0%;
  //   top: 38px;
  //   width: 26%;
  //   height: 5px;
  //   background-color: #ff7d28;
  //   border-radius: 2px;
  // }
  .problems-description-item1 {
    margin-bottom: 13px;
  }
  .slick-prev.slick-arrow {
    left: 110px;
    bottom: -70px;
    width: 82px;
    height: 26px;
    background-image: url(../images/desktop/problems/arrow_left.png);
  }
  .slick-next.slick-arrow {
    right: 110px;
    bottom: -70px;
    width: 82px;
    height: 26px;
    background-image: url(../images/desktop/problems/arrow_right.png);
  }
}
