.reviews {
  background-color: #383838;
}
.reviews.container {
  background-color: #383838;
  padding-top: 50px;
  padding-bottom: 50px;
}
.reviews .slick-list {
  overflow: hidden;
}
.reviews .slick-track {
  display: flex;
  margin-bottom: 30px;
}
.reviews .slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
}
.reviews .slick-dots button {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: none;
}
.reviews .slick-dots li {
  margin-right: 10px;
}
.before-and-after .slick-dots li:last-child {
  margin-right: 0;
}
.reviews .slick-dots li.slick-active button {
  background: radial-gradient(44.05% 44.05% at 50% 50%, #ffe790 0%, #ffaf28 100%);
}
.reviews .slick-dots li button {
  background: #c4c4c4;
  cursor: pointer;
}
.reviews ul.slick-dots {
  padding-left: 0;
}
.reviews__title {
  font-family: Montserrat;
  font-weight: 700;
  @include font(24px, 34px);
  color: #ff7d28;
  text-align: center;
  margin-bottom: 20px;
}

.reviews__card {
  height: 367px;
  background-color: #fff;
  min-width: 290px;
  margin: 0 auto;
  padding: 40px 23px;
  box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  overflow-y: auto;
  scroll-behavior: smooth;
}
.reviews__city {
  display: inline-block;
  margin-bottom: 18px;
}
.photo {
  float: left; /* Выравнивание по левому краю */
  margin: 7px 20px 0 0; /* Отступы вокруг картинки */
}

.reviews__name {
  font-family: Roboto;
  font-weight: 700;
  @include font(18px, 21px);
  color: #383838;
}
.reviews__city {
  font-family: Roboto;
  font-weight: 500;
  @include font(18px, 21px);
  color: #383838;
}
.reviews__description-text {
  font-family: Roboto;
  font-weight: normal;
  @include font(14px, 20px);
  color: #000000;
}
@media screen and (min-width: 768px) {
  .reviews__title {
    @include font(36px, 50px);
    text-align: left;
    margin-bottom: 60px;
  }

  .reviews__card {
    height: 533px;
    width: 556px;
    padding: 50px 77px;
  }
  .reviews__city {
    margin-bottom: 162px;
  }
  .photo {
    float: left; /* Выравнивание по левому краю */
    margin: 7px 24px 0 0; /* Отступы вокруг картинки */
  }

  .reviews__name {
    @include font(24px, 28px);
    display: inline-block;
    margin-bottom: 11px;
  }
  .reviews__city {
    @include font(24px, 28px);
  }
  .reviews__description-text {
    @include font(18px, 26px);
  }
}
@media screen and (min-width: 1440px) {
  .reviews.container {
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .reviews__title {
    @include font(48px, 67px);
    text-align: right;
    margin-bottom: 64px;
  }
  .reviews__title::before {
    content: '';
    position: absolute;
    display: block;
    left: 0%;
    top: 97px;
    width: 1150px;
    height: 5px;
    background-color: #ff7d28;
    border-radius: 2px;
  }

  .reviews__card {
    height: 524px;
    width: 1030px;
    padding: 80px 109px;
  }
  .reviews__city {
    margin-bottom: 39px;
  }
  .photo {
    float: left; /* Выравнивание по левому краю */
    margin: 7px 56px 0 0; /* Отступы вокруг картинки */
  }

  .reviews__name {
    @include font(24px, 28px);
    margin-bottom: 12px;
  }
  .reviews__city {
    @include font(24px, 28px);
  }
  .reviews__description-text {
    @include font(18px, 26px);
  }
}
