.container {
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

@media screen and (min-width: 375px) {
  .container {
    max-width: 425px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 769px;
  }
}
@media screen and (min-width: 1440px) {
  .container {
    max-width: 1439px;
    padding-left: 100px;
    padding-right: 100px;
  }
}
